<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <auth-card>
    <!--<auth-logo />-->
    <v-card-title>{{ $t('login') }}</v-card-title>
    <v-card-subtitle>{{ $t('loginPage.login_subtitle') }}</v-card-subtitle>
    <v-card-text>
      <app-form
        ref="form"
        v-slot="{invalid}"
        :errors="errors"
      >
        <v-container
          fluid
          class="pa-0"
        >
          <v-row v-if="loginWithUsername">
            <app-text-input
              v-model="form.login_id"
              cols="12"
              name="login_id"
              required
            />
            <app-password-input
              v-model="form.password"
              cols="12"
              hide-icon
              name="password"
              required
            />
          </v-row>
          <v-row v-else>
            <v-col
              cols="12"
            >
              <h3 class="font-weight-bold">
                {{ $t('custom.login_subtitle') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
            >
              <v-row
                align-content="center"
                justify="center"
              >
                <app-countries
                  v-model="form.country_id"
                  cols="12"
                  sm="6"
                  name="country_id"
                  required
                  :suffix="countryKey"
                  :outlined="false"
                  dense
                  @set-items="setCountries"
                />
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.login_id"
                    outlined
                    cols="12"
                    sm="6"
                    name="mobile"
                    required
                    :disabled="!form.country_id"
                    placeholder="5xxxxxxxx"
                    persistent-placeholder
                    dense
                    hide-details="auto"
                  >
                    <!--                <template #prepend-inner>
                      <div
                        class="d-flex"
                        style="min-width: 200px"
                      >
                        <app-countries
                          v-model="form.country_id"
                          cols="auto"
                          name="country_id"
                          required
                          :suffix="countryKey"
                          item-text="key"
                          @set-items="setCountries"
                        />
                        &lt;!&ndash;<v-autocomplete&ndash;&gt;
                        &lt;!&ndash;  v-model="form.country_id"&ndash;&gt;
                        &lt;!&ndash;  hide-details="auto"&ndash;&gt;
                        &lt;!&ndash;  :items="countries"&ndash;&gt;
                        &lt;!&ndash;  cols="12"&ndash;&gt;
                        &lt;!&ndash;  name="country_id"&ndash;&gt;
                        &lt;!&ndash;  required&ndash;&gt;
                        &lt;!&ndash;  sm="6"&ndash;&gt;
                        &lt;!&ndash;/>&ndash;&gt;
                      </div>
                    </template>-->
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!--<app-number-input-->
            <!--  v-model="form.login_id"-->
            <!--  :label="parseAttribute('mobile')"-->
            <!--  cols="12"-->
            <!--  name="login_id"-->
            <!--  required-->
            <!--  placeholder="5xxxxxxxx"-->
            <!--  persistent-placeholder-->
            <!--/>-->
          </v-row>
          <!--          <v-row no-gutters>
            <v-col cols="auto">
              <router-link
                :to="route(APP_ROUTES.auth.forgotPassword)"
                class="text-decoration-underline font-weight-bold"
              >
                {{ $t('loginPage.forget_password') }}
              </router-link>
            </v-col>
          </v-row>-->
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <app-btn
                :block="AppIsSmall"
                :disabled="invalid || !form.login_id"
                :loading="loading"
                @click="submit"
              >
                {{ $t('login') }}
              </app-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <h3>{{ $t('or') }}</h3>
            </v-col>
            <v-col cols="auto">
              <app-btn
                :disabled="loading"
                plain
                text
                @click="loading ? undefined : loginWithUsername = !loginWithUsername"
              >
                {{ $t('loginPage.login_with', { name: parseAttribute(loginTypeReverse) }) }}
              </app-btn>
            </v-col>
          </v-row>
          <!--          <v-row>
            <v-col cols="12">
              <p class="ma-0">
                <span>{{ $t('loginPage.no_account') }}</span>
                <router-link
                  :to="route(APP_ROUTES.auth.register)"
                  class="text-decoration-underline font-weight-bold"
                >
                  {{ $t('loginPage.create_account') }}
                </router-link>
              </p>
            </v-col>
          </v-row>-->
        </v-container>
      </app-form>
    </v-card-text>
    <!--    <v-divider />
    <v-card-actions>
      <app-btn :to="route(APP_ROUTES.homePage)">
        {{ $t('home') }}
      </app-btn>
    </v-card-actions>-->
  </auth-card>
</template>

<script>

import AuthCard from '@components/auth/AuthCard'

export default {
  name: 'Login',
  components: { AuthCard },
  data () {
    return {
      text: '',
      loading: !1,
      errors: {},
      form: {
        login_id: '',
        password: ''
      },
      loginWithUsername: !1,
      countries: []
    }
  },
  computed: {
    countryKey () {
      return this.countries.find(c => c.id === this.form.country_id)?.key
    },
    disableLogin () {
      if (this.loginWithUsername) return !(this.form.login_id && this.form.password)

      return this.form.login_id
    },
    loginTypeReverse () {
      return !this.loginWithUsername ? 'login_id' : 'mobile'
    },
    loginType () {
      return this.loginWithUsername ? 'login_id' : 'mobile'
    }
  },
  watch: {
    loginWithUsername () {
      this.form = {
        login_id: '',
        password: ''
      }
    }
  },
  mounted () {
    if (this.authUser) {
      // this.navigate(this.APP_ROUTES.homePage)
    }
    // console.log(this.$vuetify.breakpoint)
  },
  methods: {
    async submit () {
      const form = { ...this.form, push_token: window.push_token || null }
      // console.log(form)
      // if (this.loading || this.disableLogin) return
      if (this.loading) return
      this.loading = !0

      if (this.IsMobileApp) {
        form.is_mobile = !0
      }

      this.errors = {}
      try {
        const { data, _data: user, _success } = await this.apiService.auth.login(form)
        const token = data?.token
        if (_success === !0 && user) {
          if (token) {
            this.loginUser({ token, user })
          } else if (user.factorToken) {
            this.goToOtpPage(data)
          }
        }
      } catch (e) {
        // alert(e)
        const { _message, _errors } = e
        this.errors = _errors || {}
        this.form.password = ''
        const message = _message || e.message
        // message && this.alertError(JSON.stringify(Object.keys(e.request)))
        // this.alertError(JSON.stringify(e))
        message && this.alertError(message)
      } finally {
        this.loading = !1
      }
    },
    goToOtpPage (response) {
      const { data } = response || {}
      this.$router.push(this.route(this.APP_ROUTES.auth.otp, {
        mobile: this.form.login_id,
        country_id: this.form.country_id,
        token: data?.factorToken,
        resendTime: data.resend_time,
        label: response.message
      }))
    },
    setCountries (v) {
      this.countries = v
      if (v.length > 0 && !this.form.country_id) {
        this.form.country_id = v[0].id
      }
    }
  }
}
</script>
